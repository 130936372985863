import Button from "../../../Components/Button/button";
import Coding from "./Assets/images/Coding.png";
import styles from "./index.module.scss";
import { Formik, Form } from "formik";
import FormikInput from "../../../Components/FormikInput/index";
import AuthHeader from "../../../Components/AuthHeader/AuthHeader";
import { useLogin } from "./hooks/useLogin";
import { useParams } from "react-router-dom";
import Email from "../../../Routes/Authentication/Email/Email";


const Login = (): JSX.Element => {

    const {route} = useParams<{route: string}>()

    console.log(route)

    const { handleLogin, reset, isLoading, error } = useLogin();

    // const {route } = useParams<{route: string}>()

    return(
        <div className={styles["main-container"]}>
            <AuthHeader />
            <div className={styles["content-outer-container"]}>
                <div className={styles["image-container"]}>
                    <img src={Coding} alt="" />
                </div>
                <div className={styles["content-inner-container"]}>
                    <h4 className={styles["title"]}>
                        Welcome to the IQube Learning Management Portal
                    </h4>
                    <p className={styles["sub-title"]}>
                        Please Sign in with the your credentials
                    </p>

                    <Formik
                        initialValues={{
                        email: "",
                        password: "",
                        }}
                        onSubmit={(values) => {
                            // console.log(values);
                            // const {email, password} = values
                            route === 'admin'
                            ?
                                handleLogin('admin',  values)
                            :
                            route === 'mentor'
                            ?
                                handleLogin('mentor', values)
                            :
                            alert('not either')
                        }}
                        
                    >
                        <Form>
                            <FormikInput
                                label="Email"
                                placeholder="name@example.com"
                                name="email"
                                type="email"
                            />
                            <FormikInput
                                label="Password"
                                placeholder="password"
                                name="password"
                                type="password"
                            />
                            <p>Forgot Password</p>
                            <Button type="submit" disabled={isLoading}>{isLoading ? 'Please wait...' : 'Login'}</Button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Login;