import dashboard from "./assets/icons/dashboard.svg"
import tracks from "./assets/icons/tracks.svg"
import interns from "./assets/icons/interns.svg"
import mentors from "./assets/icons/mentors.svg"
import messages from "./assets/icons/messages.svg"
import logout from "./assets/icons/logout.svg"
import logo from "../../../../../assets/images/iqube-logo.png"
import style from "./style.module.scss"
import { NavLink } from "react-router-dom"
import { useState } from "react"

export const AdminSideBar = () => {

    const sideBarList = [
        {
            title: 'Dashoboard',
            icon: dashboard,
            route: '/admin/dashboard'
        },
        {
            title: 'Tracks',
            icon: tracks,
            route: '/admin/tracks'
            
        },
        {
            title: 'Interns',
            icon: interns,
            route: '/admin/interns'
        },
        {
            title: 'Mentors',
            icon: mentors,
            route: '/admin/mentors'
        },
        {
            title: 'Messages',
            icon: messages,
            route: '/admin/messages'
        },
        {
            title: 'Logout',
            icon: logout,
            route: '/admin/logout'
        }
    ]

    return(
        <div className={style['section']}>
            <div className={style['logo']}>
                <img src={logo}/>
            </div>
            <div className={style['side-bar-items-container'] }>
                {
                    sideBarList.map((items, index)=>{
                        return(
                            <NavLink
                                exact
                                to={items.route}
                                key={index}
                                activeClassName={style['active']}
                            >
                                <div className={style['side-bar-items']}>
                                    <div>                                       
                                        <img src={items.icon} alt={items.title}/>
                                    </div>
                                    <div className={style['item-title']}>
                                        <p>{items.title}</p>
                                        
                                    </div>
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const MentorSideBar = () => {
    return(
        <div>
            mentor
        </div>
    )
}