import { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SignUp from "./Routes/Authentication/SIgnUp/SignUp";
import ForgotPassword from "./Routes/Authentication/ForgotPassword/ForgotPassword";
import NewPassword from "./Routes/Authentication/NewPassword/NewPassword";
import SignIn from "./Routes/Authentication/SignIn/SignIn";
import Email from "./Routes/Authentication/Email/Email";
import SuccessMessage from "./Routes/Authentication/SuccessMessage/SuccessMessage";
import LoggedOut from "./Routes/Authentication/LoggedOut/LoggedOut";
import useAuth from "./hooks/General/useAuth";
import Login from "./pages/auth/Login"
import SuperDashboard from "./pages/admin/dashboard";
import AdminRoutes from "./Routes/Admin";
import GeneralRoute from "./Routes/General";
import AcceptInvite from "./pages/auth/AcceptInvite";
import MentorRoutes from "./Routes/Mentor";


const FallBack = () => {
  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};

function App(): JSX.Element {
  const {isLoggedIn} = useAuth()
  return (
    <Suspense fallback={<FallBack />}>
      <BrowserRouter>   
        <Route exact path="/:route/login" component={Login}/> 
        <Route exact path="/login" component={Login}/>  
        <AdminRoutes isLoggedIn={isLoggedIn}/>
        <GeneralRoute isLoggedIn={isLoggedIn} />
        <Route exact path="/accept-invite/setup/:token" component={AcceptInvite} />
        <MentorRoutes isLoggedIn={isLoggedIn}/>
          {/* <Switch>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/email" component={Email} />
            <Route exact path="/new-password" component={NewPassword} />
            <Route exact path="/sidenav" component={SideNav} />
            <Route exact path="/logged-out" component={LoggedOut} />
            <Route exact path="/success-message" component={SuccessMessage} />
          </Switch>      */}
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
