import { ReactNode } from "react"
import style from "./style.module.scss"

interface Iprop{
    children: ReactNode
}

const Content:React.FC<Iprop> = ({children}) => {
    return(
        <div className={style['section']}>
            {children}
        </div>
    )
}

export default Content