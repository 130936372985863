import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationState, userData} from '../Types';

const initialState: AuthenticationState = {
    isLoggedIn: false,
    accessToken: null,
    userData: null,

}

export const authenticationSlice =  createSlice({
    name: 'auth',
    reducers: {
        setLoginState: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setUserData: (state, action: PayloadAction<userData>) => {
            state.userData = action.payload;
        },
        clearUserData: (state) => {
        state.userData = null;
        },
          
    },
    initialState
    
})

export const { setLoginState, setUserData, clearUserData, setAccessToken } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
