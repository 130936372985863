/* eslint-disable react/display-name */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppDispatch } from '../../../index';
import { clearUserData, setLoginState } from '../../../redux/Slices/authSlice';

export interface IprotectedRoutes extends RouteProps {
  isAuth: boolean;
  isAdmin: boolean;
}

export const AdminPrivateRoute = React.memo(
  ({ component: Component, isAuth, isAdmin, ...rest }: IprotectedRoutes): JSX.Element | null => {
    const dispatch = useAppDispatch();
    if (!Component) return null;

    const logout = () => {
      localStorage.removeItem('token');
      dispatch(clearUserData);
      dispatch(setLoginState(false));
    };

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth && isAdmin ? (
            <Component {...props} />
          ) : (
            <>
              {logout()}
              <Redirect
                to={{
                  pathname: '/admin/login',
                  state: {
                    from: props.location,
                    message: 'Please Login with the required credentials',
                  },
                }}
              />
            </>
          )
        }
      />
    );
  }
);
