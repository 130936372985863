import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { loginResponseData } from "../../../../hooks/General/Types/response";
import useAuth, { Authenticate } from "../../../../hooks/General/useAuth";
import useNetworkRequest from "../../../../hooks/General/useNetworkRequest"
import { BASE_URL } from "../../../../utitlities/endpoints";
// import { BASE_URL } from "../../../../utitlities/endpoints";


interface LoginRequestData {
    email: string;
    password: string;
}

interface login {
    login: (data: LoginRequestData) => void;
    isLoading: boolean;
    error: AxiosError | null;
    data?: LoginRequestData;
    reset: () => void;
    handleLogin: (userType: string, loginResponse: LoginRequestData) => void;
    authenticate: Authenticate;
}

export const useLogin = () =>{
    const history = useHistory();
    const { authenticate, isLoggedIn } = useAuth();
    const { route } = useParams<{ route: string }>();
    const [userType, setUserType] = useState('');

    const [{ data: loginResponse, isLoading, error }, login, reset] = useNetworkRequest<
    LoginRequestData,
    loginResponseData
  >(`${BASE_URL}/admin/login`, {
    method: 'POST',
  });

  const [{ data: mentorLoginResponse }, mentorLogin] = useNetworkRequest<
  LoginRequestData,
  loginResponseData
>(`${BASE_URL}/mentor/login`, {
  method: 'POST',
});

  const handleLogin = (userType: string, loginResponse: LoginRequestData) => {
    setUserType(userType);
    if(userType === 'admin'){
      login(loginResponse);
    }else if(userType === 'mentor'){
      mentorLogin(loginResponse)
    }
    
  };

  const routeToDashboard = () => {
    switch (route?.toLowerCase()) {
      case 'admin':
        history.push(
          //@ts-ignore
          history.location.state?.from || '/admin/dashboard'
        );
        break;
      case 'mentor':
        history.push(
          //@ts-ignore
          history.location.state?.from || '/mentor/dashboard'
        );
      break;
      case 'intern':
        history.push(
          //@ts-ignore
          history.location.state?.from || '/intern/dashboard'
        );
        break;
      default:
        history.push(
          //@ts-ignore
          'https://www.google.com/'
        );
    }
  };


  useEffect(() => {
    if (loginResponse) {
      authenticate(loginResponse);
      routeToDashboard();
    }
    if (isLoggedIn) {
      routeToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse, isLoggedIn, userType]);

  return { login, handleLogin, isLoading, error, reset, authenticate };
}