import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../index';
import {
    setLoginState,
    setAccessToken,
    setUserData,
    clearUserData
} from "../../redux/Slices/authSlice"
import { loginResponseData } from './Types/response';


export type userData = {
  user:{
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  }
  accessToken: string;
  refreshToken: string;
};


export type Authenticate = (loginResponse: loginResponseData) => void;

export interface Auth {
    isLoggedIn: boolean;
    authenticate: Authenticate;
    logout: () => void;
    // isRefreshingToken: boolean;
    userData: userData | null;
}

const useAuth= () : Auth => {
    const dispatch = useAppDispatch()
    const { isLoggedIn, userData} = useAppSelector((state)=> state.auth)
    // const tokenObjectString = useMemo(() => localStorage.getItem('token'), []);
    // const tokenObject: refreshToken = tokenObjectString ? JSON.parse(tokenObjectString) : null;
    const authenticate: Authenticate = (loginResponse) => {
        if (loginResponse) {
          localStorage.setItem(
            'token',
    
            JSON.stringify(loginResponse.data.refreshToken)
          );   
          dispatch(setUserData(loginResponse.data));
          dispatch(setLoginState(true));
          dispatch(setAccessToken(loginResponse.data.accessToken));
        } else {
          throw Error('Access denied.');
        }
      };

      const logout = () => {
        localStorage.removeItem('token');
        dispatch(clearUserData);
        dispatch(setLoginState(false));
      };

    //   useEffect(() => {
    //     if (data)
    //       authenticate({
    //         ...data,
    //         refreshToken: tokenObject,
    //       } as unknown as LoginResponseData);
    //     if (error) logout();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [data, error]);

    return { isLoggedIn, userData, authenticate, logout, };
}

export default useAuth;