import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useStore } from "react-redux"
import Button from "../../../Components/Button/button"
import DashBoardLayout from "../../../Components/layout/Dashboard"
import useNetworkRequest from "../../../hooks/General/useNetworkRequest"
import { BASE_URL } from "../../../utitlities/endpoints"
import upload from "../../../assets/images/upload.png"
import style from "./addTrack.module.scss"
import { useRef } from "react"
import Modal from "../../../Components/Modal"
import { on } from "events"


const AddNewTrack = () => {
    
    const [successModal, setSuccessModal] = useState(false)

    const picRef = useRef<HTMLInputElement>(null)

    const onButtonClick = () => {
        if(picRef){
            picRef?.current?.click();
        }
        
    };

    const handleShowSucessModal = () =>{
        setSuccessModal(false)
    }

    const [{ data: addTrackResponse, isLoading }, addTrack] = useNetworkRequest
    (`${BASE_URL}/track/new`, {
    method: 'POST',
  });

  useEffect(()=>{
      if(addTrackResponse){
          setSuccessModal(true)
      }
  },[addTrackResponse])

    return(
        <DashBoardLayout userRole='admin'>
            <section className={style['section']}>
                <header>
                    <h1>Add a new  track</h1>
                </header>
                <section className={style['form-area']}>
                    <Formik
                        initialValues={{
                            title: '',
                            description: '',
                            media: ''
                        }}
                        onSubmit={(values, {resetForm})=>{
                            console.log(values)
                            addTrack({
                                title: `${values.title}`,
                                description: `${values.description}`
                            })
                            resetForm()
                            
                        }}
                    >
                        <Form>
                            <div className={style['upload-area']}>
                                <p>Upload a picture</p>
                                <div className={style['upload-box']}>
                                    <div onClick={onButtonClick}>
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div>
                                        <p>Drag and drop document here or <span onClick={onButtonClick}>Browse</span></p>
                                        <p>Supported file types: JPEG, PNG, PDF Max file size: 5mb</p>
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    name="media"
                                    aria-label="hidden"
                                    id="media"
                                    ref={picRef}
                       
                                />
                            </div>
                            <div className={style['form-group']}>
                                <label htmlFor="">Track Title</label>
                                <Field
                                    name='title'
                                    type='text'
                                />
                            </div>
                            <div className={style['form-group']} >
                                <label htmlFor="">Description</label>
                                <Field
                                    name='description'
                                    as='textarea'
                                />
                            </div>                   
                            <Button variant='primary'>
                                {isLoading? 'Adding...' : 'Add new track'}
                            </Button>                   
                        </Form>
                    </Formik>
                </section>
            </section>

            <Modal
                isOpen={successModal}
                onClickOutside={handleShowSucessModal}
            >
                <div className={style['success-modal']}>
                    <h1>You’ve Successfully Created A Track</h1>
                    <Button variant='primary' onClick={handleShowSucessModal}>Continue</Button>
                </div>

            </Modal>
        </DashBoardLayout>
    )
}

export default AddNewTrack;