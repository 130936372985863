import { Switch } from 'react-router-dom';
import MentorPrivateRoute from '../../Components/ProtectedRoutes/Mentor';
import SuperDashboard from '../../pages/admin/dashboard';
import Dashboard from '../../pages/mentor/dashboard';
import { IRoutes } from '../types';

const MentorRoutes = ({ isLoggedIn, userData }: IRoutes) => {
  console.log(userData)
  return (
    <div>
      <Switch>
        <MentorPrivateRoute
          exact
          path="/mentor/dashboard"
          component={Dashboard}
          isAuth={isLoggedIn}
          isMentor={userData?.user.role !== 'intern'}
        />
        
       </Switch>
    </div>
  );
};

export default MentorRoutes;
