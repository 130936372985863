import React, { useEffect, useRef, useState } from "react";

export default function useOnClickOutside(
  initialIsVisible: number | boolean | string
): {
  ref: React.RefObject<HTMLDivElement | HTMLUListElement> | null;
  isComponentVisible: number | boolean | string;
  setIsComponentVisible: (params: number | boolean | string) => void;
} {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    console.log("OnClick outside");
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    if (ref) {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("mousedown", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("mousedown", handleClickOutside, true);
      };
    }
  }, [ref]);

  return { ref, isComponentVisible, setIsComponentVisible };
}
