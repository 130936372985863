import React, { ButtonHTMLAttributes } from 'react';
import styles from './button.module.scss';
import Option from "./Assets/Icons/option.svg";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'outline' | 'option';
    style?: React.CSSProperties;
}

const Button = ({ style, variant = 'primary', children, className, ...rest }: ButtonProps): JSX.Element => {
    return (
        <button style={style} className={(className ? `${className} ` : '') + `${styles[`--${variant}`]}`} {...rest}>
            {variant !== "option" ? children : <img src={Option} alt="" />}
        </button>
    );
};

export default Button;
