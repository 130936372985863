import { useTable, useRowSelect } from 'react-table';
import { IReactTable } from './utils/interface';
import React, { useMemo } from 'react';
import style from './style.module.scss';
import EmptyDataVec from './assets/vectors/Empty-table.svg';

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }: { indeterminate?: any }, ref) => {
//     const defaultRef = React.useRef<any>();
//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       if ((resolvedRef as any).current) {
//         (resolvedRef as any).current.indeterminate = indeterminate;
//       }
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input
//           type="checkbox"
//           className={style['checkbox']}
//           ref={resolvedRef as React.RefObject<HTMLInputElement>}
//           {...rest}
//         />
//       </>
//     );
//   }
// );

export default function Table<T>({ config, data, children, menuitem, className, image}: IReactTable<T>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumn = useMemo(() => config, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedData = useMemo(() => data, [data]);

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: memoizedColumn,
      data: memoizedData,
    },
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       // Header: ({ getToggleAllRowsSelectedProps }) => (
    //       //   // <div>
    //       //   //   <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //       //   // </div>
    //       // ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       // Cell: ({ row }) => (
    //       //   <div>
    //       //     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //       //   </div>
    //       // ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className={`${className} ${style['table']}`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {image && <span> <img src={image} alt=''/></span>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                  <td title="menu" className={style['menu__button']}>
                    {menuitem && menuitem(row)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className={style['table--empty']}>
              <td colSpan={config.length + 1}>
                {' '}
                <div>
                  <img src={EmptyDataVec} alt="" />

                  <h3>Oops!..No Data to show</h3>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {children}
    </>
  );
}