import { ReactNode } from "react"
import Content from "./components/Content"
import {AdminSideBar} from "./components/SideBar"
import TopBar from "./components/TopBar"
import style from "./style.module.scss"


interface IDashboard{
    userRole: 'admin' | 'mentor' | 'intern' | 'undefined',
    children: ReactNode
}
const DashBoardLayout:React.FC<IDashboard> = ({userRole, children}) => {
    return(
        <div className={style['section']}>
            <div 
                className={
                    userRole === 'admin' 
                    ? 
                        style['admin-sidebar']
                    :
                    userRole === 'mentor'
                    ?
                        style['mentor-sidebar']
                    :
                        style['intern-side-bar']    
                }
            >
                <AdminSideBar/>

            </div>
            <div className={style['top-bar__content']}>
                <div className={style['top-bar']}>
                    <TopBar/>
                </div>
                <main className={style['content']}>
                    <Content>
                        {children}
                    </Content>
                </main>                
            </div>

        </div>
    )
}

export default DashBoardLayout;