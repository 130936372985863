import Logo from "../Assets/images/logo.png";
import styles from "./AuthHeader.module.scss";

function AuthHeader() {
  return (
    <div className={styles["main-container"]}>
      <div className={styles["header"]}>
        <img src={Logo} alt="" />
      </div>
    </div>
  );
}

export default AuthHeader;
