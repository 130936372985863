import useNetworkRequest from '../../../hooks/General/useNetworkRequest'
import { BASE_URL } from '../../../utitlities/endpoints';
import { TAddUser } from '../types';


export default function useAddUser() {
    const [{ data: addResponse, isLoading}, addUser] =
    useNetworkRequest<unknown, TAddUser>(
        `${BASE_URL}/admin/send-invite`,
        {
        method: 'POST',
        }
    );


  return { addResponse, addUser, isLoading };
}
