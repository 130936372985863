import Card from "../../../Components/Card";
import DashBoardLayout from "../../../Components/layout/Dashboard";
import interns from "./assets/images/interns.png"
import mentors from "./assets/images/mentors.png"
import tracks from "./assets/images/tracks.png"
import useGetTracks  from "../hooks/useGetTracks";
import {useGetInterns, useGetMentors, useGetUsers}  from "../hooks/useGetUsers";
import filter from "../../../assets/images/filter.png"
import style from "./style.module.scss"
import hourGlass from "./assets/images/track-image.png"
import deleteAction from "../../../assets/images/delete.png";
import { RefObject, useEffect } from "react";
import { internsConfig, TUsers } from "./utils/table-config";
import Table from "../../../Components/Table";
import { useState } from "react";
import useDebounce from "../../../hooks/General/useDebounce";
import Button from "../../../Components/Button/button";
import useOnClickOutside from "../../../hooks/General/useOnClickOutside";
import useDeleteUser from "../hooks/useUserActions";
import { TParams } from "../types";
import Modal from "../../../Components/Modal";


const SuperDashboard = () => {

    const [searchTerm, setSearchTerm] = useState('')
    const [deleteModal, showDeleteModal] = useState(false)
    const [id, setId] = useState('')
    
    const {tracksResponse, getTracks} = useGetTracks()
    const {usersResponse, getUsers} = useGetUsers(searchTerm)
    const {mentorsResponse, getMentors} = useGetMentors()
    const {internsResponse, getInterns} = useGetInterns()
    const {deleteResponse, isDeleting, deleteUser} = useDeleteUser(id)
   
    
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const handleShowModal = () => {
      showDeleteModal(false)
    }

    const handleSetId = ((params: string)=>{
      setId(params)
    })

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    useEffect(()=>{
        getUsers()
    },[deleteResponse])

    useEffect(()=>{
        getTracks()
    },[])

    useEffect(()=>{
        getMentors()
        getInterns()
    },[])

    useEffect(()=>{
      if(deleteResponse){
        setId('')
        handleShowModal()
      }
    },[deleteResponse])

    useEffect(
        () => {
          if (debouncedSearchTerm) {
            getUsers(searchTerm)
          } else {
            getUsers();            
          }
        },
        [debouncedSearchTerm] 
    );


    const statistics = [
        {
            title: 'Total No of Interns',
            number: internsResponse?.data?.totalDocuments,
            icon: interns
        },
        {
            title: 'Total No of Mentors',
            number: mentorsResponse?.data?.totalDocuments,
            icon: mentors
        },
        {
            title: 'Total No of Tracks',
            number: tracksResponse?.data?.length,
            icon: tracks
        }
    ]

    return(
        <DashBoardLayout userRole="admin">
            <div className={style['section']}>
               <div className={style['statistics-card']}>
                    {
                        statistics.map((statistic, index)=>{
                            return(
                                <Card className={style['card-item']} key={index}>
                                    <div>
                                        <h3>{statistic.title}</h3>
                                        <p>{statistic.number}</p>
                                    </div>
                                   <div>
                                        <img src={statistic.icon} alt='icon'/>
                                   </div>                                   
                                </Card>
                            )
                        })
                    }
               </div>
               <section  className={style['section-title']}>
                   <div>
                    <p>Available Users</p>
                   </div>
                   <div>
                       <input
                            placeholder='search'
                            onChange={(e)=>{handleSearch(e)}}
                       />
                   </div>                
               </section>
               <section className={style['content']}>
                    <Table
                        data={usersResponse?.data?.results || []}
                        config={internsConfig(hourGlass)}
                        className={style['table-styling']}
                        menuitem={(params: TParams) => (
                            params?.original?.role !== 'admin'
                            ? 
                              <div>
                                <MenuItem 
                                  params={params}
                                  handleShowModal={handleShowModal} 
                                  showDeleteModal={showDeleteModal} 
                                  id={id} 
                                  handleSetId={handleSetId} 
                                />
                              </div>
                            :
                              <div></div>
                          )}
                        
                    />
               </section>
            </div>
            <Modal
              isOpen={deleteModal}
              onClickOutside={() => handleShowModal()}
            >
              <div className={style['success-modal']}>
                  <h1>Are you sure you want to delete this user? </h1>
                  <div className={style['action-buttons']}>
                    <Button variant='outline' onClick={handleShowModal}>Cancel</Button>
                    <Button variant='primary' onClick={()=>deleteUser()}>{isDeleting? "Deleting..." : "Delete"}</Button>
                  </div>               
            </div>
          </Modal>
        </DashBoardLayout>
    )
}

export default SuperDashboard;

interface MenuParams {
    params: TParams;
    handleShowModal?:()=>void;
    showModal?:boolean
    showDeleteModal?:React.Dispatch<React.SetStateAction<boolean>>
    id: string
    handleSetId: (params: any) => void
  }
  
  const MenuItem: React.FC<MenuParams> = ({ params, showDeleteModal, id, handleSetId }) => {

    const {ref, isComponentVisible, setIsComponentVisible} = useOnClickOutside(false);
      
    return (
      <div>
        <div onClick={() => setIsComponentVisible(true)}>
          <Button variant="option" />
        </div>
        <div  className={style["menu-actions"]}>
          {isComponentVisible && 
            (
              <div ref={ref as RefObject<HTMLDivElement>}>
                  <MenuItemContent 
                    params={params}  
                    showDeleteModal={showDeleteModal} 
                    id={id} 
                    handleSetId={handleSetId} 
                  />
              </div> 
            )
          }
        </div>     
      </div>
    );
  };

  interface IMenuParams {
    params: TParams;
    handleShowModal?:()=>void;
    showModal?:boolean
    showDeleteModal?:React.Dispatch<React.SetStateAction<boolean>>
    id: string
    handleSetId: (params: any) => void
  }
  
  const MenuItemContent: React.FC<IMenuParams> = ({ params, showDeleteModal, id, handleSetId}) => {

    
    useEffect(()=>{
      if(id && showDeleteModal){
         showDeleteModal(true)
      }
    },[id, showDeleteModal])
   
    return (
      <div className={style["menu-content"]}>
        <div className={style["option"]} onClick={()=>handleSetId(params?.original?._id)} >
          <div>
            <img src={deleteAction} alt="" />
          </div>
          <div>
            <p>Delete</p>
          </div>
        </div>
       
      </div>
    );
  };
  