import { RefObject, useEffect, useState } from "react";
import Card from "../../../Components/Card";
import DashBoardLayout from "../../../Components/layout/Dashboard";
import add from "../../../assets/images/add.png";
import style from "./style.module.scss";
import SliideInForm from "../../SlideInForm";
import useGetTracks from "../hooks/useGetTracks";
import Table from "../../../Components/Table";
import { useGetMentors } from "../hooks/useGetUsers";
import { mentorsConfig } from "./utils/table-config";
import Button from "../../../Components/Button/button";
import edit from "../../../assets/images/edit.png";
import deleteAction from "../../../assets/images/delete.png";
import mail from "../../../assets/images/mail.png";
import useOnClickOutside from "../../../hooks/General/useOnClickOutside";
import useAddUser from "../hooks/useAddUser";
import { useRef } from "react";
import useDebounce from "../../../hooks/General/useDebounce";
import Modal from "../../../Components/Modal";
import { TParams } from "../types";
import useDeleteUser from "../hooks/useUserActions";


const Mentors = () => {
  const [addNewMentorForm, showAddNewMentorForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')
  const [successModal, setSuccessModal] = useState(false)
  const [deleteModal, showDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [actionType, setActionType] = useState('')
  
  const { tracksResponse, getTracks } = useGetTracks();
  const { mentorsResponse, getMentors } = useGetMentors(undefined, searchTerm);
  const { addResponse, isLoading, addUser } = useAddUser();
  const {deleteResponse, isDeleting, deleteUser} = useDeleteUser(id)

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleShowForm = () => {
    showAddNewMentorForm(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleShowSucessModal = () =>{
    setSuccessModal(false)
  }
  
  useEffect(() => {
    if (addResponse) {
        setSuccessModal(true)
    }
  }, [addResponse]);

  const handleShowModal = () => {
    showDeleteModal(false)  
  }

  const handleSetId = ((params: string, actionType: 'delete' | 'edit')=>{
    setId(params)
    setActionType(actionType)
  })

  useEffect(() => {
    getTracks();
  }, []);

  useEffect(() => {
    getMentors();
  }, [deleteResponse]);

  useEffect(()=>{
    if(deleteResponse){
      setId('')
      handleShowModal()
    }
  },[deleteResponse])

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getMentors(undefined, searchTerm)
      } else {
        getMentors();            
      }
    },
    [debouncedSearchTerm] 
  );  


  return (
    <DashBoardLayout userRole="admin">
      <div className={style["section"]}>
        <header>
          <h1>Registerd Mentors</h1>
          <p>These are the Mentors currently registered on the LMS</p>
        </header>
        <section className={style["add-new-mentor-container"]}>
          <Card className={style["add-new-mentor-card"]}>
            <img
              src={add}
              alt="add"
              onClick={() => showAddNewMentorForm(true)}
            />
            <p className={style["card-header"]}>Add new Mentor</p>
            <p className={style["card-content"]}>
              Click on the icon to add a new mentor to the workspace
            </p>
          </Card>
        </section>
        <div className={style["search-box-container"]}>
          <input 
            placeholder="Search" 
            onChange={(e)=>{handleSearch(e)}}
          />
        </div>
        <section className={style["content"]}>
          <Table
            config={mentorsConfig}
            data={mentorsResponse?.data?.results || []}
            className={style["table-styling"]}
            menuitem={(params: TParams) => (
              <div>
                <MenuItem 
                  params={params}
                  handleShowModal={handleShowModal} 
                  showDeleteModal={showDeleteModal} 
                  id={id} 
                  handleSetId={handleSetId}
                />
              </div>
            )}
          />
        </section>
      </div>
      <SliideInForm
        isOpen={addNewMentorForm}
        onClose={handleShowForm}
        header="Mentor"
        tracks={tracksResponse}
        addUser={addUser}
        isLoading={isLoading}
        successModal={successModal}
        handleShowSuccessModal={handleShowSucessModal}
      />
       <Modal
          isOpen={deleteModal}
          onClickOutside={handleShowModal}
       >
          <div className={style['success-modal']}>
            {    
              <h1>Are you sure you want to delete this user? </h1>             
            }
            <div className={style['action-buttons']}>
              <Button variant='outline' onClick={handleShowModal} disabled={isDeleting}>Cancel</Button>
              <Button variant='primary' onClick={()=> deleteUser()}>{isDeleting? "Deleting..." : 'Delete'}</Button>
            </div>
              
          </div>

      </Modal>
      
    </DashBoardLayout>
  );
};

export default Mentors;

interface MenuParams {
  params: TParams;
  showDeleteModal?:React.Dispatch<React.SetStateAction<boolean>>
  id: string
  handleSetId: (params: string, actionType: 'delete' | 'edit') => void
  handleShowModal: () => void
}

const MenuItem: React.FC<MenuParams> = ({ params, showDeleteModal, id, handleSetId, handleShowModal }) => {


  const { ref, isComponentVisible, setIsComponentVisible } =
    useOnClickOutside(false);
  
  return (
    <div>
      <div onClick={() => setIsComponentVisible(true)}>
        <Button variant="option" />
      </div>
      <div className={style["menu-actions"]}>
        {isComponentVisible && (
          <div ref={ref as RefObject<HTMLDivElement>}>
            <MenuItemContent 
              params={params} 
              showDeleteModal={showDeleteModal} 
              id={id} 
              handleSetId={handleSetId}
              handleShowModal={handleShowModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MenuItemContent: React.FC<MenuParams> = ({ params, id, showDeleteModal, handleSetId }) => {

  useEffect(()=>{
    if(id && showDeleteModal){
       showDeleteModal(true)
    }
  },[id, showDeleteModal])
 

  return (
    <div className={style["menu-content"]}>
      {/* {params?.inviteAccepted ? (
        <div className={style["option"]}>
          <div>
            <img src={mail} alt="" />
          </div>
          <div>
            <p>Send Invite Link</p>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* <div className={style["option"]}>
        <div>
          <img src={edit} alt="" />
        </div>
        <div>
          <p>Edit</p>
        </div>
      </div> */}
      <div className={style["option"]} onClick={()=>handleSetId(params?.original?._id, 'delete')}>
        <div>
          <img src={deleteAction} alt="" />
        </div>
        <div>
          <p>Delete</p>
        </div>
      </div>

     
    </div>
  );
};
