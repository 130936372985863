import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../Components/ProtectedRoutes/General';
import Messages from '../../pages/general/messages';

const GeneralRoute = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  return (
    <Switch>
      {/* Protected routes start */}

      <PrivateRoute exact path="/admin/messages" component={Messages} isAuth={isLoggedIn} />
    </Switch>
  );
};

export default GeneralRoute;