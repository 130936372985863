import style from "./style.module.scss"
import notification from "./assets/icons/notification.svg"
import pic from "./assets/images/pic.png"
import { useAppSelector } from "../../../../.."

const TopBar = () => {
    const {userData} = useAppSelector((state)=> state.auth)
    return(
        <div className={style['section']}>
            <div className={style['notification']}>
                <img src={notification} alt='notification'/>
            </div>
            <div className={style['user-data']}>
               {
                   userData?.user?.role === 'admin' 
                   ? 
                    <div className={style['user-role']}>Admin</div>
                   :
                    <div>
                        <p>Josephine Shobande</p>
                        <p>{userData?.user?.role}</p>
                        
                    </div>
               }
            </div>
            <div >
                {
                    userData?.user?.role !== 'admin'
                    ?
                        <img src={pic} alt='pic'/>
                    :
                        ''
                }
            </div>
        </div>
    )
}

export default TopBar;