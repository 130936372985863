import style from './style.module.scss'
// import hourGlass from "../assets/images/track-image.png"
import flower from "../../../../assets/images/flower.jpeg"


export type TUsers = {
    firstName: string;
    lastName: string;
    role: string;
    tracks: {
      title: string;
      id: string
    }[];
    inviteAccepted: boolean;
    _id: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
    inviteToken?: any;
    inviteTokenExpires?: any;
    passwordConfirm?: any;
    profileImageUrl: any
}


export const internsConfig = (hourGlass: string)=> [
    {
      Header: 'NAME',
      accessor: '',
       Cell: function CellAction({ row }: { row: { original: TUsers } }): JSX.Element {
        return (
          <div className={style['name-details']}>
            <div className={style['profile-image']}>
              {
                row?.original?.profileImageUrl
                ?
                  <img src={row?.original?.profileImageUrl} />
                :
                  <p>{row?.original.email.slice(0,2)}</p>

              }
              {/* <img src={row?.original?.profileImageUrl || flower} /> */}
              
              
            </div> 
            <div className={style['name']}>
              <p>
                {
                    row?.original?.firstName
                    ?
                      <span>{`${row?.original.firstName} ${row?.original?.lastName}` }</span>
                    :
                      <span> {row?.original.email.slice(0, 3)} </span>             
                }
              </p>            
            </div>          
            
          </div>
        );
      },

    },
    {
      Header: 'EMAIL',
      accessor: 'email',

    },
    {
      Header: 'ROLE',
      accessor: 'role', 
      Image: hourGlass
      
    },
    {
      Header: 'TRACK',
      accessor: 'track',
      
      // Image: `${hourGlass}`

      Cell: function CellAction({ row }: { row: { original: TUsers } }): JSX.Element {
        return (
          <div>
              {
                row?.original?.tracks[0]?.title
              }
          </div>
        );
      },
    }
  ];
  