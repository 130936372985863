import { Field } from "formik";
import styles from "./index.module.scss";

interface InputProps {
  label: string;
  placeholder: string;
  name: string;
  type?: string;
}

export default function Input({
  label,
  placeholder,
  name,
  type = "text",
  ...rest
}: InputProps) {
  return (
    <div className={styles["field-container"]}>
      <label className={styles["label"]} htmlFor={`${name}`}>
        {label}
      </label>
      <Field
        className={styles["field"]}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
}
