import style from "./style.module.scss";
import hourGlass from "../../../../assets/icons/filter.png"
import flower from "../../../../assets/images/flower.jpeg";
import { FilterByTrack } from "../../filter";

export type TInterns = {
  name: string;
  role: string;
  tracks: {
    title: string;
    id: string
  }[];
  inviteAccepted: boolean;
  _id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  inviteToken?: any;
  inviteTokenExpires?: any;
  passwordConfirm?: any;
  profileImageUrl: any;
};

export const internsConfig = (onFilter: (column: "track"|'status') => void, handleShowFilter:() => void, filterDropDown: boolean) => [
  {
    Header: "NAME",
    accessor: "",
    Cell: function CellAction({
      row,
    }: {
      row: { original: TInterns };
    }): JSX.Element {
      return (
        <div className={style["name-details"]}>
          <div className={style["profile-image"]}>
            {row?.original?.profileImageUrl ? (
              <img src={row?.original?.profileImageUrl} />
            ) : (
              <p>{row?.original.email.slice(0, 2)}</p>
            )}
            {/* <img src={row?.original?.profileImageUrl || flower} /> */}
          </div>
          <div className={style["name"]}>
            <p>
              {row?.original?.name ? (
                <span>{row?.original.name}</span>
              ) : (
                <span> {row?.original.email.slice(0, 3)} </span>
              )}
            </p>
          </div>
        </div>
      );
    },
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    id: "Track",
    Header: (props: any) => (
      <div className={style['track']} onClick={handleShowFilter} >
        <span onClick={() => onFilter("track")}>TRACK</span>
        <img src={hourGlass} alt=''/>
        {/* <div>
        {
          filterDropDown && <FilterByTrack/>
        } 
        
        </div> */}
      </div>
      
    ),
    accessor: "",
    Cell: function CellAction({ row }: { row: { original: TInterns } }): JSX.Element {
      return (
        <div>
            {
              row?.original?.tracks[0]?.title
            }
        </div>
      );
    },
  },
  {
    id: "status",
    Header: 'STATUS',
    accessor: "",
    Cell: function CellAction({
      row,
    }: {
      row: { original: TInterns };
    }): JSX.Element {
      return (
        <div>
          {row.original.inviteAccepted ? (
            <div className={style["enrolled"]}>Enrolled</div>
          ) : (
            <div className={style["pending"]}>Pending</div>
          )}
        </div>
      );
    },
  },
];
