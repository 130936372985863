import useNetworkRequest from '../../../hooks/General/useNetworkRequest'
import { BASE_URL } from '../../../utitlities/endpoints';
import { TGetAllTracks } from '../types';


export default function useGetTracks() {
    const [{ data: tracksResponse}, getTracks] =
    useNetworkRequest<unknown, TGetAllTracks>(
        `${BASE_URL}/track`,
        {
        method: 'GET',
        }
    );


  return { tracksResponse, getTracks };
}
