import Modal from "../../Components/Modal";
import style from "./style.module.scss"
import { Field, Form, Formik} from 'formik';
import cancel from "../../assets/images/cancel.png"
import Button from "../../Components/Button/button";
import { TGetAllTracks } from "../admin/types";
import { useState } from "react";
import { useEffect } from "react";
import { useGetMentors } from "../admin/hooks/useGetUsers";


interface ISlide{
    isOpen: boolean
    onClose: () => void
    header: string
    tracks: TGetAllTracks | null
    addUser: (body?: unknown, params?: unknown) => void
    isLoading?: boolean
    successModal?: boolean
    handleShowSuccessModal?: () => void
}

const SliideInForm:React.FC<ISlide> = ({isOpen, onClose, header, tracks, addUser, isLoading, successModal, handleShowSuccessModal}) => {

    const [showmentors, setShowMentors] = useState(false)
    const [trackId, setTrackId] = useState('')
    const [mentorId, setMentorId] = useState('')

    const {mentorsResponse, getMentors} = useGetMentors(trackId)

    const handleShowMentors = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setShowMentors(true)
        setTrackId(e.target.value)
    }

    const handleChooseMentor = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMentorId(e.target.value)
    }
    

    useEffect(()=>{
        if(trackId && header == 'Intern'){
            getMentors(trackId)
        }
    },[trackId])


    useEffect(()=>{
        if(mentorsResponse){
            console.log(mentorsResponse)
        }
    },[mentorsResponse])

    return(
       <Modal isOpen={isOpen} onClickOutside={() => onClose()}>
           <div
            className={style['_slideInform--overlay']}
            onClick={() => {
                onClose();
            }}
            >
            </div>
            <section className={`${isOpen ? style['form__open'] : style['form__close']}`}>
                <div className={style['cancel-icon']} onClick={onClose}>
                    <img src={cancel} alt=''/>
                </div>
                <header>
                    <h1>Add a new {header}</h1>
                    <p>Complete the required information</p>
                </header>
                <section className={style['form-area']}>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            track: '',
                            mentor: ''
                        }}
                        onSubmit={(values, {resetForm})=>{
                            // console.log(values)
                            header == 'Mentor'
                            ?
                                addUser({
                                email: values.email,
                                role: 'mentor',
                                tracks: trackId
                                })
                                
                            :
                                addUser({
                                    email: values.email,
                                    role: 'intern',
                                    tracks: trackId,
                                    mentorId: mentorId
                                })
                                resetForm()
                        }}
                    >
                        <Form>
                            <div className={style['form-group']}>
                                <label htmlFor="">Name</label>
                                <Field
                                    name='name'
                                    type='text'
                                />
                            </div>
                            <div className={style['form-group']} >
                                <label htmlFor="">Email Adress</label>
                                <Field
                                    name='email'
                                    type='email'
                                />
                            </div>
                            <div className={style['form-group']}>
                                <label htmlFor="">Choose Track</label>
                                <select 
                                    name='track'
                                    onChange={(e)=>handleShowMentors(e)}
                                    defaultValue=''
                                >
                                    <option value=""   disabled hidden>Choose here</option>
                                    {
                                        tracks?.data?.map((track, index)=>{
                                            return(
                                                <option
                                                    value={track._id}
                                                    key={index}
                                                >
                                                    {track.title}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {
                                showmentors && header == 'Intern'
                                ?
                                    <div className={style['form-group']} >
                                        <label htmlFor="">Choose Mentor</label>
                                        <select
                                            name='mentor'
                                            onChange={(e)=>{handleChooseMentor(e)}}
                                            defaultValue=''
                                        >
                                            <option value=""   disabled hidden>Choose here</option>
                                            {
                                                mentorsResponse?.data?.results.map((mentor, index)=>{
                                                    return(
                                                        mentor?.inviteAccepted &&
                                                        <option
                                                            value={mentor._id}
                                                            key={index}
                                                        >   
                                                            {
                                                                `${mentor?.firstName} ${mentor?.lastName}`
                                                            }
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                :
                                ''  
                            }
                            <Button variant='primary' type='submit'>
                                {isLoading ? 'Please wait...' : 'Send Invite'}
                            </Button>                   
                        </Form>
                    </Formik>
                </section>
            </section>
            <Modal
                isOpen={successModal}
                onClickOutside={()=>handleShowSuccessModal}
            >
                <div className={style['success-modal']}>
                    <h1>You’ve Successfully added a {header}</h1>
                    <Button variant='primary' onClick={handleShowSuccessModal}>Continue</Button>
                </div>

            </Modal>
       </Modal>
    )
}

export default SliideInForm;