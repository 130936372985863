import { Switch } from 'react-router-dom';
import { AdminPrivateRoute } from '../../Components/ProtectedRoutes/Admin';
import SuperDashboard from '../../pages/admin/dashboard';
import Interns from '../../pages/admin/interns';
import Mentors from '../../pages/admin/mentors';
import Tracks from '../../pages/admin/tracks';
import AddNewTrack from '../../pages/admin/tracks/AddNewTrack';
import { IRoutes } from '../types';

const AdminRoutes = ({ isLoggedIn, userData }: IRoutes) => {
  console.log(userData)
  return (
    <div>
      <Switch>
        <AdminPrivateRoute
          exact
          path="/admin/dashboard"
          component={SuperDashboard}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/interns"
          component={Interns}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/interns/edit/:id"
          component={Interns}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
         <AdminPrivateRoute
          exact
          path="/admin/mentors"
          component={Mentors}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/tracks"
          component={Tracks}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/tracks/add-new-track"
          component={AddNewTrack}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
        <AdminPrivateRoute
          exact
          path="/admin/tracks/edit/:id"
          component={AddNewTrack}
          isAuth={isLoggedIn}
          isAdmin={userData?.user.role !== 'intern'}
        />
       </Switch>
    </div>
  );
};

export default AdminRoutes;
