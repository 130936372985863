import Card from "../../../Components/Card";
import DashBoardLayout from "../../../Components/layout/Dashboard";
import add from "../../../assets/images/add.png";
import style from "./style.module.scss";
import { RefObject, useEffect, useState } from "react";
import useGetTracks from "../hooks/useGetTracks";
import { useGetInterns } from "../hooks/useGetUsers";
import SliideInForm from "../../SlideInForm";
import Table from "../../../Components/Table";
import { internsConfig, TInterns } from "./utils/table-config";
import useOnClickOutside from "../../../hooks/General/useOnClickOutside";
import Button from "../../../Components/Button/button";
import edit from "../../../assets/images/edit.png";
import deleteAction from "../../../assets/images/delete.png";
import mail from "../../../assets/images/mail.png";
import useAddUser from "../hooks/useAddUser";
import useDebounce from "../../../hooks/General/useDebounce";
import { FilterByTrack } from "../filter";
import useDeleteUser from "../hooks/useUserActions";
import { TParams } from "../types";
import Modal from "../../../Components/Modal";
import { Link, useHistory } from "react-router-dom";

const Interns = () => {

  let history = useHistory()

  const [addNewInternForm, showAddNewInternForm] = useState(false);
  const [successModal, setSuccessModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterDropDown, setFilterDropDown] = useState(false)
  const [deleteModal, showDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [actionType, setActionType] = useState('')

  const { tracksResponse, getTracks } = useGetTracks();
  const { internsResponse, getInterns } = useGetInterns(searchTerm);
  const { addResponse, isLoading, addUser } = useAddUser();
  const {deleteResponse, isDeleting, deleteUser} = useDeleteUser(id)


  const debouncedSearchTerm = useDebounce(searchTerm, 500);


  const handleShowForm = () => {
    showAddNewInternForm(false);
    history.push(`/admin/interns`)
  };
  const handleShowFilter = () => {
    setFilterDropDown(!filterDropDown)
  }
  const handleFilter = (columnToSort:"track"|'status') => {
    console.log(columnToSort);
  };
  
  const handleShowSucessModal = () =>{
    setSuccessModal(false)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleShowModal = () => {
    showDeleteModal(false)
    
  }

  const handleSetId = ((params: string, actionType: 'delete' | 'edit')=>{
    setId(params)
    setActionType(actionType)
  })

  useEffect(()=>{
    if(deleteResponse){
      setId('')
      handleShowModal()
    }
  },[deleteResponse])

  useEffect(() => {
    if (addResponse) {
      setSuccessModal(true)
    }
  }, [addResponse]);

  useEffect(() => {
    getTracks();
  }, []);

  useEffect(() => {
    getInterns();
  }, [deleteResponse]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getInterns(searchTerm)
      } else {
        getInterns();            
      }
    },
    [debouncedSearchTerm] 
  );

  return (
    <DashBoardLayout userRole="admin">
      <div className={style["section"]}>
        <header>
          <h1>Registerd Interns</h1>
          <p>These are the Interns currently registered on the LMS</p>
        </header>
        <section className={style["add-new-intern-container"]}>
          <Card className={style["add-new-intern-card"]}>
            <img
              src={add}
              alt="add"
              onClick={() => showAddNewInternForm(true)}
            />
            <p className={style["card-header"]}>Add new intern</p>
            <p className={style["card-content"]}>
              Click on the icon to add a new intern to the workspace
            </p>
          </Card>
        </section>
        <div className={style["search-box-container"]}>
          <input placeholder="Search" onChange={(e)=>{handleSearch(e)}} />
        </div>
        <section className={style["content"]}>
          <Table
            config={internsConfig(handleFilter, handleShowFilter, filterDropDown)}
            data={internsResponse?.data?.results || []}
            className={style["table-styling"]}
            menuitem={(params: TParams) => (
              <div>
                <MenuItem 
                  params={params} 
                  showAddNewInternForm={showAddNewInternForm}
                  handleShowModal={handleShowModal} 
                  showDeleteModal={showDeleteModal} 
                  id={id} 
                  handleSetId={handleSetId} 
                />
              </div>
            )}
          >
          </Table>
        </section>
      </div>
      <SliideInForm
        isOpen={addNewInternForm}
        onClose={handleShowForm}
        header="Intern"
        tracks={tracksResponse}
        addUser={addUser}
        successModal={successModal}
        handleShowSuccessModal={handleShowSucessModal}
        isLoading={isLoading}
      />
      <Modal
          isOpen={deleteModal}
          onClickOutside={handleShowModal}
      >
          <div className={style['success-modal']}>
            {
              <h1>Are you sure you want to delete this user? </h1> 
            }
            <div className={style['action-buttons']}>
              <Button variant='outline' onClick={handleShowModal}>Cancel</Button>
              <Button variant='primary' onClick={()=>{deleteUser()}}>{isDeleting ? "Deleting..." : "Delete"}</Button>
            </div>
              
          </div>

      </Modal>
     
    </DashBoardLayout>
  );
};

export default Interns;

interface MenuParams {
  params: TParams;
  showAddNewInternForm: React.Dispatch<React.SetStateAction<boolean>>
  showModal?:boolean
  showDeleteModal?:React.Dispatch<React.SetStateAction<boolean>>
  id: string
  handleSetId: (params: string, actionType: 'delete' | 'edit') => void
  handleShowModal: () => void
}

const MenuItem: React.FC<MenuParams> = ({ params, showDeleteModal, id, handleSetId }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOnClickOutside(false);
  
  return (
    <div>
      <div onClick={() => setIsComponentVisible(true)}>
        <Button variant="option" />
      </div>
      <div className={style["menu-actions"]}>
        {isComponentVisible && (
          <div ref={ref as RefObject<HTMLDivElement>}>
            <MenuItemContent 
              params={params} 
              showDeleteModal={showDeleteModal} 
              id={id} 
              handleSetId={handleSetId} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface IMenuParams {
  params: TParams;
  showDeleteModal?:React.Dispatch<React.SetStateAction<boolean>>
  id: string
  handleSetId: (params: string, actionType: 'delete' | 'edit') => void
}

const MenuItemContent: React.FC<IMenuParams> = ({ params, showDeleteModal, id, handleSetId }) => {
  
  useEffect(()=>{
    if(id && showDeleteModal){
       showDeleteModal(true)
    }
  },[id, showDeleteModal])
 
  return (
    <div className={style["menu-content"]}>
      {/* {params?.original.inviteAccepted ? (
        ''
        ) : (
        <div className={style["option"]}>
          <div>
            <img src={mail} alt="" />
          </div>
          <div>
            <p>Send Invite Link</p>
          </div>
        </div>
      )} */}
     
        {/* <div className={style["option"]} onClick={()=>handleSetId('edit')}>
          <div>
            <img src={edit} alt="" />
          </div>
          <div>
            <p>Edit</p>
          </div>
        </div> */}
     
      
      <div className={style["option"]} onClick={()=>handleSetId(params?.original?._id, 'delete')}>
        <div>
          <img src={deleteAction} alt="" />
        </div>
        <div>
          <p>Delete</p>
        </div>
      </div>
      
    </div>
  );
};
