import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppDispatch } from '../../../index';
import { clearUserData, setLoginState } from '../../../redux/Slices/authSlice';

export interface IprotectedRoutes extends RouteProps {
    isAuth: boolean;
    isMentor: boolean;
  }

export const MentorPrivateRoute = React.memo(
    ({ component: Component, isAuth, isMentor, ...rest }: IprotectedRoutes): JSX.Element | null => {
      const dispatch = useAppDispatch();
      if (!Component) return null;
  
      const logout = () => {
        localStorage.removeItem('token');
        dispatch(clearUserData);
        dispatch(setLoginState(false));
      };
  
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuth && isMentor ? (
              <Component {...props} />
            ) : (
              <>
                {logout()}
                <Redirect
                  to={{
                    pathname: '/mentor/login',
                    state: {
                      from: props.location,
                      message: 'Please Login with the required credentials',
                    },
                  }}
                />
              </>
            )
          }
        />
      );
    }
  );

export default MentorPrivateRoute