import { useState } from "react";
import { useAppSelector } from "../../..";
import DashBoardLayout from "../../../Components/layout/Dashboard"
import empty from "./assets/images/empty.png"
import style from "./style.module.scss"


const Messages = () => {
    const { isLoggedIn, userData} = useAppSelector((state)=> state.auth)
    const [emptyState, setEmptyState] = useState(true)
    return(
        <DashBoardLayout userRole={userData?.user?.role === "admin"? "admin" : 'intern'}>
            <div className={style['section']}>
                {
                    emptyState 
                    ? 
                        <div className={style['empty-state']}>
                            <header>
                                <h1>Messages</h1>
                            </header>
                            <div className={style['empty-state-content']}>
                                <img src={empty} alt=""/>
                                <p>Looks like you don’t have any new messages - yet</p>
                                <small>Your new messages will appear here</small>
                            </div>
                        </div>
                    :
                        <div></div>
                }
               
            </div>
        </DashBoardLayout>
    )
}

export default Messages;