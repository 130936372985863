import React, {HTMLAttributes} from "react";
import styles from "./index.module.scss"


interface DivProps extends HTMLAttributes<HTMLDivElement> {
    style?: React.CSSProperties;
}

const Card = ({style, children, className, ...rest}:DivProps): JSX.Element => {
    return(
        <div style={style} className={`${className} ${styles['_']}`} {...rest}>
            {children}
        </div>
    )
}

export default Card;