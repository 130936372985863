import Button from "../../../Components/Button/button";
import Coding from "./Assets/images/Coding.png";
import { Formik, Form } from "formik";
import FormikInput from "../../../Components/FormikInput/index";
import AuthHeader from "../../../Components/AuthHeader/AuthHeader";
import styles from "./style.module.scss"


const AcceptInvite = () => {
    return(
        <div className={styles["main-container"]}>
            <AuthHeader />
            <div className={styles["content-outer-container"]}>
                <div className={styles["image-container"]}>
                    <img src={Coding} alt="" />
                </div>
                <div className={styles["content-inner-container"]}>
                    <h4 className={styles["title"]}>
                        Welcome to the IQube Learning Management Portal
                    </h4>
                    <p className={styles["sub-title"]}>
                        Create a password to get started
                    </p>

                    <Formik
                        initialValues={{
                        password: "",
                        confirmPassword: "",
                        }}
                        onSubmit={(values) => {
                        values.password = "";
                        values.confirmPassword = "";
                        console.log(values);
                        }}
                    >
                        <Form>
                        <FormikInput
                            label="Create Password"
                            placeholder="password"
                            name="password"
                            type="password"
                        />
                        <FormikInput
                            label="Verify Password"
                            placeholder="password"
                            name="confirmPassword"
                            type="password"
                        />
                        <Button type="submit">Proceed</Button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AcceptInvite