import React from 'react';
import { Dispatch } from 'redux';
import ReactDOM from 'react-dom';
import './base.scss';
import App from './App';
import { store } from './redux/Store/store';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector  } from 'react-redux';
import './base.scss'
import * as serviceWorker from './serviceWorker';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
