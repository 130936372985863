import useNetworkRequest from '../../../hooks/General/useNetworkRequest'
import { BASE_URL } from '../../../utitlities/endpoints';



export default function useDeleteUser(id:string) {
    const [{ data: deleteResponse, isLoading:isDeleting}, deleteUser] =
    useNetworkRequest(
        `${BASE_URL}/admin/delete-user/${id}`,
        {
        method: 'DELETE',
        }
    );


  return { deleteResponse, isDeleting, deleteUser };
}