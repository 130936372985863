import DashBoardLayout from "../../../Components/layout/Dashboard"
import style from "./style.module.scss"
import useGetTracks from "../hooks/useGetTracks"
import { RefObject, useEffect } from "react"
import Card from "../../../Components/Card"
import add from "../../../assets/images/add.png"
import trackImg from "./assets/images/track-image.png"
import Button from "../../../Components/Button/button"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import useOnClickOutside from "../../../hooks/General/useOnClickOutside"
import deleteAction from "../../../assets/images/delete.png"
import edit from "../../../assets/images/edit.png"
import Modal from "../../../Components/Modal"
import AddNewTrack from "./AddNewTrack"
// import MenuActions from "../../../Components/Menu"


const Tracks = () => {

    const { tracksResponse, getTracks } = useGetTracks()
    const history = useHistory()
    const { ref, isComponentVisible, setIsComponentVisible } = useOnClickOutside(false);

    const [addNewTrack, showAddNewTrack]  = useState(false)
    const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null)

    const handleShowMenuActions = (index:number) => {
        setActiveMenuIndex(index)        
        setIsComponentVisible(!isComponentVisible)        
    }
   
    useEffect(()=>{
        if(addNewTrack){
            history.push('/admin/tracks/add-new-track')
        }
    },[addNewTrack])

    useEffect(()=>{
        getTracks()
    },[])
    useEffect(()=>{
        if(tracksResponse){
            console.log(tracksResponse)
        }
    },[tracksResponse])

    useEffect(() => {
        function handleClickOutside(event: Event) {
          if (
            (ref?.current && !ref?.current.contains(event.target as Node)) ||
            ref?.current?.isEqualNode(event.target as Node)
          ) {
            setIsComponentVisible(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);

    return(
        <DashBoardLayout userRole='admin'>
            <section className={style['section']}>
                <header>
                    <h1>Available Tracks</h1>
                    <p>These are the tracks currently available on the LMS</p>
                </header>
                <section className={style['content']}>
                    <Card className={style['empty-card-container']}>                       
                            <img src={add} alt='' onClick={()=>showAddNewTrack(true)}/>       
                        <div>
                            <p>Add new track</p>
                            <small>Click on the icon to create a new track</small>
                        </div>
                    </Card>
                    
                    {
                        tracksResponse?.data?.map((track, index)=>{
                            return(
                                <div >
                                    <Card className={style['track-card-container']} key={index} >
                                        <div>
                                            <img src={trackImg} alt=''/>
                                        </div>
                                        <div>
                                            <p>{track.title}</p>
                                            <small>
                                                {
                                                    track.description
                                                    ?
                                                        track.description
                                                    :
                                                        track.title
                                                    
                                                }
                                            </small>
                                        </div>
                                        <div >
                                            <Button variant='option' onClick={() => handleShowMenuActions(index)}/> 
                                            <div className={style['menu']}>
                                                {isComponentVisible && activeMenuIndex===index ? 
                                                    (
                                                        <div ref={ref as RefObject<HTMLDivElement>} >
                                                            <MenuActions id={track._id}/>
                                                        </div>
                                                         
                                                    ): ''
                                                }
                                            </div>  
                                        </div>
                                                                        
                                    </Card>
                                    {/* {
                                        addNewTrack && <AddNewTrack/>
                                    } */}
                                </div>                               
                            )                           
                        })
                    }                   
                </section>
            </section>
        </DashBoardLayout>
    )
}

export default Tracks

interface IMenuProps{
    id: string
}

const MenuActions:React.FC<IMenuProps> = ({id}) => {

    let history = useHistory()

    const [successModal, showSuccessModal] = useState(false)
    const [actionType, setActionType] = useState('')

    const handleSetId = ((actionType: 'delete' | 'edit')=>{
        setActionType(actionType)
      })
    
      const handleShowModal = () => {
        showSuccessModal(false)
      }
    
      useEffect(()=>{
        if(id && actionType== 'delete'){
          showSuccessModal(true)
        }
      },[id, actionType])

      useEffect(()=>{
          if(id && actionType == 'edit'){
            history.push(`/admin/tracks/edit/${id}`)
          }
      },[id, actionType])

    return(
        <div className={style['menu-section']} >
            <div  className={style['edit-action']} onClick={()=>handleSetId('edit')}>
                <img src={edit} alt='edit'/>
                <span>Edit</span>
            </div>
            <div className={style['delete-action']} onClick={()=>handleSetId('delete')}>
                <img src={deleteAction} alt='delete'/>
                <span>Delete</span>
            </div>
                <Modal
                    isOpen={successModal}
                    onClickOutside={()=>handleShowModal}
                >
                    <div className={style['success-modal']}>
                        {
                        actionType === 'delete'
                        ?
                            <h1>Are you sure you want to delete this track? </h1>
                        :
                            ''
                        }
                        <div className={style['action-buttons']}>
                        <Button variant='outline'>Cancel</Button>
                        <Button variant='primary'>Delete track</Button>
                        </div>
                        
                    </div>

                </Modal>
        </div>
    )
}

