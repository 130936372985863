import useNetworkRequest from '../../../hooks/General/useNetworkRequest'
import { BASE_URL } from '../../../utitlities/endpoints';
import { TGetUsers} from '../types';




export  function useGetUsers(searchParam?: string) {
    const [{ data: usersResponse}, getUsers] =
    useNetworkRequest<unknown, TGetUsers>(
        `${BASE_URL}/user?search=${searchParam}`,
        {
        method: 'GET',
        }
    );
  return { usersResponse, getUsers };
}

export  function useGetInterns(searchParam?:string) {
  const [{ data: internsResponse}, getInterns] =
  useNetworkRequest<unknown, TGetUsers>(
      `${BASE_URL}/user?role=intern&search=${searchParam? searchParam : ''}`,
      {
      method: 'GET',
      }
  );
  return { internsResponse, getInterns };
}

export  function useGetMentors(id?:string, searchParam?: string) {
  const [{ data: mentorsResponse}, getMentors] =
  useNetworkRequest<unknown, TGetUsers>(
      id
      ?
        `${BASE_URL}/user?role=mentor&trackId=${id}`
      : 
        `${BASE_URL}/user?role=mentor&search=${searchParam? searchParam : ''}`,
      {
      method: 'GET',
      }
  );
  return { mentorsResponse, getMentors };
}
