import { useDispatch, useSelector } from 'react-redux';
import useAxiosWithRedux from 'use-axios-with-redux';
import { NetworkRequestType } from 'use-axios-with-redux';
import { AxiosRequestConfig } from 'axios';
import { useAppSelector } from '../../index';

export type ReduxConfigType = {
  action?: string;
  callback?: () => void;
};

export const useNetworkRequest = <RequestDataType, ResponseDataType>(
  url: string,
  config?: AxiosRequestConfig,
  reduxConfig?: ReduxConfigType
): NetworkRequestType<RequestDataType, ResponseDataType> => {
  const token = useAppSelector((state) => state.auth?.accessToken);
  const headers = {
    // "Access-Control-Allow-Origin": "*",
    Accept: 'application/json, text/plain, multipart/form-data, */*',
    'X-Requested-With': 'XMLHttpRequest',
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
  };

  return useAxiosWithRedux<RequestDataType, ResponseDataType>(
    url,
    {
      ...(reduxConfig || {}),
      useSelector: useSelector, // Add a react redux selector hook
      appDispatch: useDispatch, // Add a react redux dispatch object
    },
    { ...(config || {}), headers: { ...config?.headers, ...headers } }
  );
};

export default useNetworkRequest;
